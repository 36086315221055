<template>
  <span>
    {{ formatter(dateTime, format) }}
  </span>
</template>

<script lang="ts">
import { computed } from 'vue'
import { useDateFormat } from '~/composables'

export default {
  name: 'DateFormat',
  props: {
    dateTime: { type: [Date, String], required: true },
    showTime: { type: Boolean, default: () => true },
  },
  setup(props) {
    const format = computed(() =>
      props.showTime ? 'MMM DD, YYYY - h:mm A' : 'MMM DD, YYYY'
    )
    const formatter = useDateFormat

    return {
      format,
      formatter,
    }
  },
}
</script>

<style scoped></style>
